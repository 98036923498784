import moment from 'moment';

function parse(startdate,enddate,options){
  return {
    startdate: {
      timestamp: startdate.unix(),
      ymdhms: startdate.format('YYYY-MM-DD HH:mm:ss'),
      ymd: startdate.format('YYYY-MM-DD'),
    },
    enddate: {
      timestamp: enddate.unix(),
      ymdhms: enddate.format('YYYY-MM-DD HH:mm:ss'),
      ymd: enddate.format('YYYY-MM-DD')
    },
    options
  }
}

export default {
  "today": function(){
    return parse(
      moment().startOf('day'),
      moment().endOf('day'),
      {group: 'hourly'}
    );
  },

  "yesterday": function(){
    return parse(
      moment().subtract(1,'day').startOf('day'),
      moment().subtract(1,'day').endOf('day'),
      {group: 'hourly'}
    );
  },

  "tomorrow": function(){
    return parse(
      moment().add(1,'day').startOf('day'),
      moment().add(1,'day').endOf('day'),
      {group: 'hourly'}
    );
  },

  "thisWeek": function(){
    return parse(
      moment().startOf('isoWeek').startOf('day'),
      moment().endOf('isoWeek').endOf('day'),
      {group: 'daily'}
    );
  },

  "nextWeek": function(){
    return parse(
      moment().add(1,'week').startOf('isoWeek').startOf('day'),
      moment().add(1,'week').endOf('isoWeek').endOf('day'),
      {group: 'daily'}
    );
  },

  "lastWeek": function(){
    return parse(
      moment().subtract(1,'week').startOf('isoWeek').startOf('day'),
      moment().subtract(1,'week').endOf('isoWeek').endOf('day'),
      {group: 'daily'}
    );
  },

  "thisMonth": function(){
    return parse(
      moment().startOf('month').startOf('day'),
      moment().endOf('month').endOf('day'),
      {group: 'daily'}
    );
  },

  "nextMonth": function(){
    return parse(
      moment().add(1,'month').startOf('month').startOf('day'),
      moment().add(1,'month').endOf('month').endOf('day'),
      {group: 'daily'}
    );
  },

  "lastMonth": function(){
    return parse(
      moment().subtract(1,'month').startOf('month').startOf('day'),
      moment().subtract(1,'month').endOf('month').endOf('day'),
      {group: 'daily'}
    );
  },

  "thisYear": function(){
    return parse(
      moment().startOf('year').startOf('day'),
      moment().endOf('day'),
      {group: 'monthly'}
    );
  },

  "lastYear": function(){
    return parse(
      moment().subtract(1,'year').startOf('year').startOf('day'),
      moment().subtract(1,'year').endOf('year').endOf('day'),
      {group: 'monthly'}
    );
  },
}

export function enumerateDays(starttime,endtime){
  let datestart = moment.unix(starttime);
  let dateend = moment.unix(endtime);

  let labels = [];
  let step = 0;
  while(datestart.add(step, 'days').diff(dateend) < 0) {
    let day = datestart.clone();
    labels.push(day);
    step = 1;
  }

  return labels;
}

export function enumerateMonths(starttime,endtime){
  let datestart = moment.unix(starttime);
  let dateend = moment.unix(endtime);

  let labels = [];
  let step = 0;
  while(datestart.add(step, 'months').diff(dateend) < 0) {
    let day = datestart.clone();
    labels.push(day);
    step = 1;
  }

  return labels;
}
