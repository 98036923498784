<template>
  <canvas :id="id"></canvas>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  props: ['id','analytics'],
  data(){
    return {
      chart: null,
      config: {
        type: 'bar',
        data: {
          labels: [],
          datasets: []
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            },
          }
        },
      }
    }
  },

  methods: {
    render(){
      if(this.chart){
        this.chart.destroy();
      }

      let data = Object.assign(this.config,{
        data: {
          labels: this.analytics.labels,
          datasets: this.analytics.datasets
        }
      });

      this.chart = new Chart(document.getElementById(this.id), Object.assign({},data));
    }
  },
  mounted() {
    this.render();
  },

  watch: {
    "analytics.datasets": function(){
      this.render();
    }
  }
}
</script>
