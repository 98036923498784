<template>
  <b-card bg-variant="light" text-variant="dark">
    <template #header>
      <DaterangeDropdown @onSelected="setDaterange" :items="dateranges" initSelected="thisYear" class="float-right"/>
      <h6 class="mb-0">{{title}}</h6>
      <small>{{daterange}}</small>
    </template>
    <BarChart v-if="stats" id="accounts-chart" :analytics="stats"/>
  </b-card>
</template>

<script>
import BarChart from '@/common/components/Charts/Bar';
//import analytics from '@/common/services/analytics';
import DaterangeDropdown from './parts/DaterangeDropdown';
import {getChartData} from '@/common/components/Analytics/parts/ChartDataFunc.js';
import moment from 'moment';
export default {
    props: [],
    components: {
      BarChart,DaterangeDropdown
    },
    data(){
      return {
        title: "New Users",
        stats: {
          labels: [],
          datasets: []
        },
        daterange: null,
        dateranges: ['thisWeek','lastWeek','thisMonth','lastMonth','thisYear','lastYear','allTime']
      };
    },


    methods: {
      setDaterange({item, selection}){
        if(item.id === "allTime"){
          this.daterange = 'All time';
          selection = null;
        }
        else{
          this.daterange = (selection.startdate.ymd === selection.enddate.ymd) ? selection.startdate.ymd : `${selection.startdate.ymd} / ${selection.enddate.ymd}`;
        }

        this.fetchData(selection);
      },

      fetchData(selection){
        let group = (selection === null ? 'monthly' : selection.options.group);
        this.$api.get('analytics/users',{
          params: {
            datestart: selection ? moment.unix(selection.startdate.timestamp).format('YYYY-MM-DD') : null,
            dateend: selection ? moment.unix(selection.enddate.timestamp).format('YYYY-MM-DD') : null,
            groupped: group
          }
        }).then(res => {
          let data = getChartData('Accounts',{
            startdate: selection ? selection.startdate.timestamp : null,
            enddate: selection ? selection.enddate.timestamp : null
          })[group](res.data);
          this.stats = Object.assign({},data);
        })
      }
    },

    mounted(){

    }
};
</script>
