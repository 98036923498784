<template>
    <div id="dashboard" class="page">
      <b-card-group deck v-if="stats">
       <b-card bg-variant="light" text-variant="dark" class="text-center">
         <b-card-text>
           <h1>{{ stats.users.totalUsers }}</h1>
           <h6 class="text-uppercase">Total Accounts</h6>
         </b-card-text>

         <template #footer>
           <b-row>
             <b-col class="text-left"><strong>{{ stats.users.topUsers }}</strong> Accounts</b-col>
             <b-col class="text-right"><strong>{{ stats.users.childUsers }}</strong> Sub-accounts</b-col>
           </b-row>
         </template>
       </b-card>

       <b-card bg-variant="light" text-variant="dark" class="text-center">
         <b-card-text>
           <h1>{{ stats.rtc.totalCalls }}</h1>
           <h6 class="text-uppercase">Total Calls</h6>
         </b-card-text>
         <template #footer>
           <b-row>
             <b-col class="text-left"><strong>{{stats.rtc.totalChannels}}</strong> Channels</b-col>
             <b-col class="text-right"><strong>{{stats.rtc.totalDuration}}</strong></b-col>
           </b-row>
         </template>
       </b-card>
     </b-card-group>

     <b-row class="mt-3">
       <b-col lg="12" md="12" sm="12">
         <AccountsChart/>
       </b-col>
       <b-col>

       </b-col>
     </b-row>
    </div>
</template>

<script>
import AccountsChart from '@/common/components/Analytics/Accounts';
export default {
    props: ['user'],
    components: {
      AccountsChart
    },
    data(){
      return {
        page: {
          title: "Dashboard"
        },
        breadcrumbs: [
          {
            title: "Dashboard"
          }
        ],
        stats: null,
      };
    },


    methods: {
      getStatistics(){
        this.$api.get('statistics').then((res) => {
          this.stats = res.data;
        });
      }
    },

    mounted(){
      this.getStatistics();
      this.$emit('set-sidebar',{active: "dashboard"});
      this.$emit('set-navbar',{active: "dashboard"});
    }
};
</script>
