<template>
  <b-dropdown v-if="selected" size="sm" variant="primary" id="dropdown-2" :text="selected.text" right>
    <template v-for="(item,key) in options">
      <b-dropdown-item :active="selected.id === item.id" @click="setOption(item.id)" :key="'t-'+key">{{item.text}}</b-dropdown-item>
      <b-dropdown-divider v-if="item.divider" :key="'d-'+key"></b-dropdown-divider>
    </template>
  </b-dropdown>
</template>
<script>
import dateranges from './DaterangeFunc.js';

export default {
    props: ['initSelected','items'],
    components: {

    },
    data(){
      return {
        selected: null,
        options: [],
      };
    },

    methods: {
      setOption(id){
        this.selected = this.options.find(o => o.id === id);
        let selection = this.selected.id === 'allTime' ? null : dateranges[this.selected.id]();
        this.$emit('onSelected', {item: this.selected, selection: selection});
      },

      setOptions(){
        let arr = [
          {id: "today", text: "Today"},
          {id: "tomorrow", text: "Tomorrow"},
          {id: "yesterday", text: "Yesterday", divider: true},
          {id: "thisWeek", text: "This Week"},
          {id: "nextWeek", text: "Next Week"},
          {id: "lastWeek", text: "Last Week", divider: true},
          {id: "thisMonth", text: "This Month"},
          {id: "nextMonth", text: "Next Month"},
          {id: "lastMonth", text: "Last Month", divider: true},
          {id: "thisYear", text: "This Year"},
          {id: "nextYear", text: "Next Year"},
          {id: "lastYear", text: "Last Year", divider: true},
          {id: "allTime", text: "All Time"},
        ];

        this.options = arr.filter(o => this.items.indexOf(o.id) !== -1);
      }
    },

    mounted(){
      this.setOptions();
      this.setOption(this.initSelected || "today");
    }
};
</script>
