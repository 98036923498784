import moment from 'moment';
import {enumerateDays,enumerateMonths} from '@/common/components/Analytics/parts/DaterangeFunc.js';

export function getChartData(label, {startdate,enddate}){
  let dataset = {
    label: label,
    data: [],
    backgroundColor: [],
    borderColor: [],
    borderWidth: 1
  };

  let colours = {
    default: 'rgba(0,0,0,0.5)',
    increase: 'rgba(0, 128, 0,0.5)',
    decrease: 'rgba(255, 0, 0,0.5)'
  };

  const getColour = function(data,prevData){
    let colour = colours.default;
    if(data && prevData){
      if(data.total > prevData.total){
        colour = colours.increase;
      }

      if(data.total < prevData.total){
        colour = colours.decrease;
      }

      if(data.total === prevData.total){
        colour = colours.default;
      }

    }

    if(data && !prevData){
      if(data.total > 0){
        colour = colours.increase;
      }
    }

    return colour;
  };

  return {
    "daily": (results) => {
      let days = enumerateDays(startdate,enddate);
      let stats = {
        labels: [],
        datasets: []
      };
      for(let i in days){
        let day = days[i];
        stats.labels.push(day.format('DD MMM'));

        let idx = day.format('YYYY-MM-DD');
        let data = results.find(o => o.idx === idx);

        let prevIdx = day.clone().subtract(1,'day').format('YYYY-MM-DD');
        let prevData = results.find(o => o.idx === prevIdx);

        if(prevData){
          dataset.backgroundColor.push(getColour(data,prevData));
        }

        dataset.data.push(data ? data.total : 0);
      }
      stats.datasets.push(dataset);

      return stats;
    },
    "monthly": (results) => {
      let labelFormat = 'MMM';
      if(startdate === null && enddate === null){
        startdate = results[0] ? moment(results[0].idx+'-01').unix() : null;
        enddate = results[results.length - 1] ? moment(results[results.length - 1].idx+'-01').unix() : null;
        labelFormat = 'MMM YYYY';
      }
      let months = enumerateMonths(startdate,enddate);
      let stats = {
        labels: [],
        datasets: []
      };

      for(let i in months){
        let m = months[i];
        stats.labels.push(m.format(labelFormat));

        let idx = m.format('YYYY-MM');
        let data = results.find(o => o.idx === idx);

        let prevIdx = m.clone().subtract(1,'month').format('YYYY-MM');
        let prevData = results.find(o => o.idx === prevIdx);

        dataset.backgroundColor.push(getColour(data,prevData));

        dataset.data.push(data ? data.total : 0);
      }
      stats.datasets.push(dataset);

      return stats;
    }
  }
}
